@import 'variables';
body{
    background-color: #eeeeee;
}
img{
    background-repeat: no-repeat;
}
.logo{
    max-width: 200px;
}
.barraNav{
    background-color: var(--primario);
}
.navbar-dark .navbar-nav .nav-link{
    color: var(--terciario);
}
@media (max-width: 768px) {
    .navbar-dark .navbar-nav .nav-link{
        border-bottom: 1px solid var(--terciario);
        display: flex;
        justify-content: center;
    }
}
.campus{
    color: var(--terciario);
    /* color: rgba(255,255,255,.5); */
}
.campus:hover {
    color: rgba(255,255,255,.7);
}
.footerFondo{
    background-color: var(--primario);
}
.footerContacto{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
}
@media (max-width: 576px) {
    .footerContacto{
        font-size:0.8rem;
    }
}
.footerBoton{
    background-color: var(--naranja);
    border-color: var(--naranja);
    font-size: 1.5rem;
    color: var(--terciario);
}
.footerBoton:hover{
    background-color: var(--primario);
    border-color: var(--terciario);
    color: var(--terciario);
}
.footerLogo{
    margin: auto;
}
.footerLogo2{
    display: flex;
}
.footerCopy p{
    background-color: var(--naranja);
    color: var(--terciario);
    text-align: center;
    margin-bottom: 0rem;
}
.gridNuestrosCursos{
    display: grid;
    grid-template-columns: repeat(4,400px);
    justify-content: space-evenly;
    align-items: center
}
.imgBanner{
    height: 500px;
    max-height: 500px;
    width:auto;
    transition-duration:.3s; 
    transition-property:transform,-webkit-transform;
    width:100%
}
.imgBanner:hover{
    -webkit-transform:scale(1.2);
    transform:scale(1.2);
}
.imgCurso{
    height: 250px;
    max-height: 250px;
    width:auto;
    border-radius: 20px;
    margin-top: 1rem;
}
.btnCard{
    color: var(--terciario);
    width: 10rem;
    border-radius: 10px;
}
.btnCard:hover{
    background-color: var(--primario);
    border-color: var(--primario);
    color: var(--terciario);
}
.grid2 .card {
    border-radius: 20px;
    
}
.cardBodyFlex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.grid2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
}
@media (min-width: 576px) {
    .grid2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        
    }
}

@media (min-width: 768px) {
    .grid2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }
}

@media (min-width: 992px) {
    .grid2 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
    }
}

@media (min-width: 1200px) {
    .grid2 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
    }
}
.fechaInscripciones{
    text-align: left;
    font-size: 15px;
    font-weight: bold;
}
h5{
    font-size: 30px;
}
.fixed-bottom { 
    /* Esta propiedad hace refencia al boton de enviar del footer, se agrego para solucionar el bug de superposión, ya que esto hacia que no se puede selecionar el celular ni el boton de enviar*/
    left: unset;
}
.btnNosotros{
    background-color: var(--naranja);
    border-color: var(--naranja);
    color: var(--terciario);
    border-radius: 10px;
}
.btnNosotros:hover{
    background-color: var(--primario);
    border-color: var(--terciario);
    color: var(--terciario);
}
.textNosotros{
    border-left-style: solid;
    border-left-color: var(--naranja);
}

.nosotros{
    padding-right: 5rem;
}

.img_nosotros{
    width:auto;
    transition-duration:.3s; 
    transition-property:transform,-webkit-transform;
    width:100%
}
.img_nosotros:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
}

@media (max-width: 768px) {
    .nosotros {
        padding: 1rem 2rem 1rem 2rem;
        text-align: center;
    }
    .h2,.h4, a{
        text-align: center;
    }
    .imgNosotros{
        padding: 0 0 0 15px;
    }
}
.imgDocente{
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    border-style: none;
}
.fondoCard{
    background-color: rgba(255,255,255,.5);
    transition-property: transform;
    transition-duration: 300ms;
}

.fondoCard:hover{
    transform: scale(1.1);
}

.cardDocente{
    color: var(--terciario);
    display: flex;
    align-items: center;
    padding: 0;
}
.descripcionDocente{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.colorNosotros{
    background-color: var(--primario);
}


/* Nuestros talleres */

.nuestrosTalleres{
    text-transform: uppercase;
    margin: 3rem 0;
}
.imgTaller{
    max-width: 100%;
    display: block;
    width: 30rem;
    -o-object-fit: cover;
    object-fit: cover;
    transition-duration:.3s; 
    transition-property:transform,-webkit-transform;
}

.imgTaller:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
}

.imagenTalleres{
    position: relative;
    display: inline-block;
    /* text-align: center; */
}

.galeria-talleres{
    display: grid;
    gap: 2rem;
}
@media (min-width: 526px) {
    .galeria-talleres{
        grid-template-columns: repeat(1, 1fr);
    }
}
@media (min-width: 712px) {
    .galeria-talleres{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 1024px) {
    .galeria-talleres{
        grid-template-columns: repeat(3, 1fr);
    }
}
/* Nuestros cursos */
.disponibilidadActivo{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: lightgreen;
}
.disponibilidadInactibo{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: crimson;
}
.btnInstagram{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    border: 0;
}
.colorDark{
    background-color: #050505 !important;
}
.btnAmarrillo{
    background-color:#e4a81a;
    border-color:#e4a81a;
}
.btnNaranja{
    background-color: var(--naranja);
    border-color: var(--naranja);
}
.text-naranja{
    color: var(--naranja) !important;
}
.text-amarillo{
    color: #e4a81a;
}

/* Curso detalle */
.img-detalle{
    max-height: 100%;
    max-width: 100%;
}
.img-detalle-curso{
    height: 200px;
    width: 350px;
    border-radius: 1rem;
}

.descripcion_talleres{
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: bold;
    background-color: #ffffff;
    color: #2d3748;
    width: 100%;
}
.descripcion_talleres_body{
    background-color: #ffffff;
    width: 100%;
}